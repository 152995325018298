<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <div v-if="!isForbidIdentity">
      <v-subheader>
        答题账号
        <v-spacer></v-spacer>
      </v-subheader>
      <v-divider></v-divider>
      <v-slide-y-transition>
        <div>
          <div class="d-flex align-end">
            <v-text-field
              :disabled="identityDisabled"
              :label="localPersonInfo.loginIdentity.loginKeyType.typeText"
              hide-details="auto"
              v-model="localPersonInfo.loginIdentity.loginKey"
              :loading="isFieldLoading"
              :error.sync="isPersonLoginKeyInvalid"
              :error-messages="identityKeyErrorMsg"
            >
              <template #prepend-inner>
                <v-slide-x-transition>
                  <v-icon v-if="isLoginKeyPassedAllCheck" color="success">
                    {{ isLoginKeyPassedAllCheck ? "mdi-check" : "" }}
                  </v-icon>
                </v-slide-x-transition>
              </template>
            </v-text-field>
            <AppMenuBtn
              v-if="!identityDisabled"
              color="primary"
              icon="mdi-swap-horizontal"
              tooltip="切换账号类型"
            >
              <v-list>
                <v-list-item
                  v-for="idType in identityTypes"
                  :key="idType.value"
                  @click="applyTypeToIdentity(idType)"
                >
                  {{ idType.text }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
          </div>
          <div class="d-flex align-end">
            <v-text-field
              :disabled="identityDisabled || localPersonInfo.hasPassword"
              type="password"
              label="登录密码"
              hide-details="auto"
              v-model="localPersonInfo.password"
              :rules="fieldRules.userNewPwd"
            ></v-text-field>
            <AppTooltipBtn
              v-if="!identityDisabled && localPersonInfo.hasPassword"
              color="primary"
              icon="mdi-lock-reset"
              :tooltip="passwordResetLabel"
              @click="startChangePassword"
            />
          </div>
        </div>
      </v-slide-y-transition>
    </div>
    <v-subheader class="mt-6">基本信息</v-subheader>
    <v-divider></v-divider>
    <div v-for="field in visibleFieldConfigList" :key="field.guid">
      <v-text-field
        v-if="
          isTextConfigType(field.configType) ||
            isNumberConfigType(field.configType)
        "
        :label="field.fieldAlias"
        hide-details="auto"
        v-model="localPersonInfo[field.fieldName]"
        :rules="buildTextFieldRules(field)"
        :disabled="
          !isCreatingNew && localDisabledFields.includes(field.fieldName)
        "
      >
      </v-text-field>
      <AppDateRangePicker
        v-if="isDateTimeConfigType(field.configType)"
        :clearable="false"
        :dense="false"
        :label="field.fieldAlias"
        :is-range="false"
        :date-max="currentDateStr"
        prepend-inner-icon=""
        v-model="localPersonInfo[field.fieldName]"
        :rules="field.isRequired ? fieldRules.required : []"
      >
        <template #append v-if="isDateOfBirthField(field.fieldName)">
          <span class="mt-1 text--secondary">
            {{ localPersonAgeDisplay }}
          </span>
        </template>
      </AppDateRangePicker>
      <v-select
        v-if="isSelectConfigType(field.configType)"
        :key="field.guid"
        :label="field.fieldAlias"
        hide-details="auto"
        :items="field.valueRange"
        v-model="localPersonInfo[field.fieldName]"
        :rules="field.isRequired ? fieldRules.required : []"
        :disabled="
          !isCreatingNew && localDisabledFields.includes(field.fieldName)
        "
        @change="
          personFieldChanged(field.fieldName, localPersonInfo[field.fieldName])
        "
      ></v-select>
    </div>
    <CredentialsPasswordDialog
      v-model="isShowChangePasswordDialog"
      :user-guid="localPersonInfo.userGuid"
      :is-new-pwd="!localPersonInfo.hasPassword"
      @closed="closeChangePassword"
    />
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import _ from "lodash";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppMessageBox from "@/components/AppMessageBox";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import CredentialsPasswordDialog from "@/components/CredentialsPasswordDialog";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { fetchVisibleIdentityTypeList } from "@/api/userIdentity";
import {
  fetchTestPersonAgeFromDob,
  fetchIsLoginKeyDuplicated
} from "@/api/person";
import { buildDateString } from "@/utils/dateTime";
import { buildAgeString } from "@/utils/personUtils";
import { configType, specialConfigField } from "@/utils/constants/fieldConfig";

export default {
  components: {
    AppLoadingSpinner,
    AppTooltipBtn,
    AppMenuBtn,
    AppMessageBox,
    AppDateRangePicker,
    CredentialsPasswordDialog
  },

  props: {
    // 是否正在显示，用于加载 identityType
    isDisplaying: {
      type: Boolean,
      required: true
    },
    isForbidIdentity: {
      type: Boolean,
      default: false
    },
    isCreatingNew: {
      type: Boolean,
      default: false
    },
    nodeGuids: {
      type: Array,
      required: true
    },
    regionGuid: {
      type: String,
      required: true
    },
    personInfo: {
      type: Object,
      required: true
    },
    disabledFields: {
      type: Array
    },
    identityDisabled: {
      type: Boolean,
      default: false
    },
    noUpdateFields: {
      type: Array
    },
    fieldConfigList: {
      type: Array
    },
    nodeList: {
      type: Array
    }
  },

  model: {
    prop: "personInfo",
    event: "changed"
  },

  data() {
    return {
      isLoading: false,
      isFieldLoading: false,
      localPersonInfo: this.personInfo,
      localDisabledFields: this.disabledFields || [],
      localNoUpdateFields: this.noUpdateFields || [],
      localFieldConfigList: this.fieldConfigList || [],
      localNodeList: this.nodeList || [],
      localPersonAgeDisplay: "",
      tempPersonLoginKey: "",
      isPersonLoginKeyInvalid: false,
      identityKeyErrorMsg: "",
      identityTypes: [],
      fieldRules: {
        userNewPwd: [
          val =>
            (val || "").length < 1 ||
            (val || "").length >= 6 ||
            "新密码不能少于6位"
        ],
        required: [val => (`${val}` || "").length > 0 || "必填"]
      },
      // dialog
      isShowChangePasswordDialog: false,
      errorMsg: ""
    };
  },

  watch: {
    personInfo: {
      handler(newVal) {
        this.localPersonInfo = newVal;
        // 设置密码TextField的显示
        if (this.localPersonInfo.hasPassword) {
          this.localPersonInfo.password = "this is hidden password";
        }
      },
      immediate: true,
      deep: true
    },
    localPersonInfo: {
      handler(newVal) {
        if (newVal && newVal.loginIdentity && newVal.loginIdentity.loginKey) {
          newVal.loginIdentity.loginKey = newVal.loginIdentity.loginKey.trim();
        }
        this.$emit("changed", newVal);
      },
      immediate: true,
      deep: true
    },
    "localPersonInfo.loginIdentity.loginKey": {
      handler() {
        this.identityKeyTyped();
      },
      immediate: true
    },
    "localPersonInfo.dateOfBirth": {
      handler(newVal) {
        this.updatePersonAgeFromDob(newVal);
      },
      immediate: true
    },
    async isDisplaying(newVal) {
      if (newVal && !this.isForbidIdentity) {
        await this.getIdentityTypesAndApplyFirst();
        // 切换显示时，记录当前的识别号，并重新校验
        this.tempPersonLoginKey = this.localPersonInfo.loginIdentity.loginKey;
        await this.validateLoginKey();
      }
    },
    async isCreatingNew(newVal) {
      if (!newVal && !this.isForbidIdentity) {
        // 当更新Person时，记录当前的识别号，并重新校验
        this.tempPersonLoginKey = this.localPersonInfo.loginIdentity.loginKey;
        await this.validateLoginKey();
      }
    },
    noUpdateFields(newVal) {
      this.localNoUpdateFields = newVal;
    },
    localNoUpdateFields(newVal) {
      this.$emit("update:no-update-fields", newVal);
    },
    fieldConfigList(newVal) {
      this.localFieldConfigList = newVal;
    },
    nodeList(newVal) {
      this.localNodeList = newVal;
    }
  },

  computed: {
    visibleFieldConfigList() {
      return this.localFieldConfigList.filter(conf => conf.isVisible);
    },
    currentDateStr() {
      return buildDateString(new Date());
    },
    passwordResetLabel() {
      return this.localPersonInfo.hasPassword ? "修改密码" : "添加新密码";
    },
    isLoginKeyPassedAllCheck() {
      return (
        !this.isPersonLoginKeyInvalid &&
        this.localPersonInfo.loginIdentity.loginKey
      );
    }
  },

  methods: {
    isNodeListFound(nodeList) {
      return nodeList && nodeList.length;
    },
    isDateOfBirthField(fieldName) {
      return (
        fieldName && fieldName === specialConfigField.dateOfBirth.fieldName
      );
    },
    isTextConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.text.value;
    },
    isNumberConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.number.value;
    },
    isSelectConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.select.value;
    },
    isDateTimeConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.datetime.value;
    },
    // ====================== Field Validation - IdentityKey ===============================
    async validateLoginKey() {
      if (!this.identityDisabled) {
        let loginKeyError = "";
        let loginKey = this.localPersonInfo.loginIdentity.loginKey;
        let loginKeyType = this.localPersonInfo.loginIdentity.loginKeyType;
        let regPattern = new RegExp(loginKeyType.rulesRegex);
        if (!loginKey) {
          loginKeyError = `请输入${loginKeyType.typeText}`;
        } else if (!regPattern.test(loginKey)) {
          loginKeyError = loginKeyType.rulesMessage;
        } else if (loginKey !== this.tempPersonLoginKey) {
          // 校验识别号是否重复
          let isDuplicated = await this.checkIsLoginKeyDuplicated(loginKey);
          loginKeyError = isDuplicated
            ? `该${loginKeyType.typeText}已存在`
            : "";
        }
        if (loginKeyError) {
          this.isPersonLoginKeyInvalid = true;
          this.identityKeyErrorMsg = loginKeyError;
        } else {
          this.isPersonLoginKeyInvalid = false;
          this.identityKeyErrorMsg = "";
        }
      }
    },
    identityKeyTyped: _.debounce(function() {
      this.validateLoginKey();
    }, 500),
    async checkIsLoginKeyDuplicated(loginKey) {
      let isDuplicated = true;
      try {
        this.isFieldLoading = true;
        isDuplicated = await fetchIsLoginKeyDuplicated(loginKey);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isFieldLoading = false;
      return isDuplicated;
    },
    // ====================== Field Validation - BasicInfo ===============================
    buildTextFieldRules(fieldObj) {
      let ruleRegex = [
        val => {
          if (this.isTextConfigType(fieldObj.configType)) {
            //文本框输入字符串长度限定
            if (fieldObj.strLength) {
              return (
                new RegExp("^.{0," + fieldObj.strLength + "}$").test(val) ||
                "不得超过" + fieldObj.strLength + "个字符"
              );
            }
          }
          if (this.isNumberConfigType(fieldObj.configType)) {
            //输入的必须是数字
            return (
              new RegExp("^[0-9]+\\.{0,1}[0-9]{0,9}$").test(val) || "请输入数字"
            );
          }
          // 对于 secured 的字段，由于会加星号，所以不校验
          // 对于没有设置 regex 的 field，不校验直接通过
          let regPattern = new RegExp(fieldObj.rulesRegex);
          return regPattern.test(val) || fieldObj.rulesMessage;
        }
      ];

      let numRangeRegex = this.isNumberConfigType(fieldObj.configType)
        ? this.numRangeRegExp(fieldObj.numRange)
        : [];
      let requiredRegex = fieldObj.isRequired ? this.fieldRules.required : [];
      return [...requiredRegex, ...ruleRegex, ...numRangeRegex];
    },
    //数字大小区间判断
    numRangeRegExp(numRange) {
      let numRangeRules = [];
      if (numRange && Object.keys(numRange).length > 0) {
        let objRange = JSON.parse(numRange);
        let minVal = objRange.minVal;
        let maxVal = objRange.maxVal;
        let numRangeErr = "";
        if (minVal && maxVal) {
          numRangeErr = "请输入：" + minVal + "-" + maxVal + "的数字";
          numRangeRules = [
            v =>
              (parseInt(v) <= parseInt(objRange.maxVal) &&
                parseInt(v) >= parseInt(objRange.minVal)) ||
              numRangeErr
          ];
        } else {
          if (minVal) {
            numRangeErr = "不得小于" + minVal;
            numRangeRules.push([
              v => parseInt(v) >= parseInt(objRange.minVal) || numRangeErr
            ]);
          }
          if (maxVal) {
            numRangeErr = "不得大于" + maxVal;
            numRangeRules.push([
              v => parseInt(v) >= parseInt(objRange.maxVal) || numRangeErr
            ]);
          }
        }
      }
      return numRangeRules;
    },
    // ====================== Change password ===============================
    startChangePassword() {
      this.isShowChangePasswordDialog = true;
    },
    closeChangePassword() {
      this.isShowChangePasswordDialog = false;
    },
    // ====================== Add Identity ===============================
    async getIdentityTypesAndApplyFirst() {
      if (!this.identityTypes.length) {
        await this.getVisibleIdentityTypes();
      }
      if (this.isCreatingNew && this.identityTypes.length) {
        this.applyTypeToIdentity(this.identityTypes[0]);
      }
    },
    applyTypeToIdentity(identityType) {
      this.localPersonInfo.loginIdentity.loginKeyType = {
        typeValue: identityType.value,
        typeText: identityType.text,
        rulesRegex: identityType.rulesRegex,
        rulesMessage: identityType.rulesMessage
      };
      // 切换识别码类型时，重新校验
      this.validateLoginKey();
    },
    // ====================== Prepare data ===============================
    async getFieldConfig() {
      try {
        this.isLoading = true;
        this.localFieldConfigList = await getUserFieldConfig(this.nodeGuids);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getlocalNodeList() {
      try {
        this.isLoading = true;
        this.localNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getVisibleIdentityTypes() {
      try {
        this.isLoading = true;
        let visibleIdTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          null,
          this.nodeGuids
        );
        if (!visibleIdTypes || !visibleIdTypes.length) {
          this.errorMsg = "尚未添加任何识别号类型，请联系管理员进行添加。";
        }
        this.identityTypes = visibleIdTypes;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async updatePersonAgeFromDob(dateOfBirth) {
      if (dateOfBirth) {
        try {
          this.isLoading = true;
          let personAgeInfo = await fetchTestPersonAgeFromDob(dateOfBirth);
          this.localPersonInfo.age = {
            year: personAgeInfo.year,
            month: personAgeInfo.month,
            day: personAgeInfo.day
          };
          this.localPersonAgeDisplay = buildAgeString(this.localPersonInfo.age);
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    prepareFieldConfig() {
      if (!this.localFieldConfigList || !this.localFieldConfigList.length) {
        return this.getFieldConfig();
      }
    },
    preparelocalNodeList() {
      if (!this.localNodeList || !this.localNodeList.length) {
        return this.getlocalNodeList();
      }
    },
    personFieldChanged(fieldName, newVal) {
      if (fieldName === specialConfigField.node.fieldName) {
        let node = this.localNodeList.find(d => d.value === newVal);
        if (node) {
          this.localPersonInfo.nodeName = node.text;
        } else {
          this.localPersonInfo.nodeName = "";
        }
      }
    }
  },

  async created() {
    // LoginIdentity
    if (!this.isForbidIdentity) {
      this.tempPersonLoginKey = this.localPersonInfo.loginIdentity.loginKey;
      await this.getIdentityTypesAndApplyFirst();
    }
    // 需要先加载 fieldConfig
    await this.prepareFieldConfig();
    // 初始状态下，secured 的 fields 就是不需要 update 的 fields
    // NodeSelectList
    await this.preparelocalNodeList();
    if (this.isNodeListFound(this.localNodeList)) {
      let nodeConfig = this.localFieldConfigList.find(
        fc => fc.fieldName === specialConfigField.node.fieldName
      );
      nodeConfig.configType = configType.select.value;
      nodeConfig.valueRange = this.localNodeList;
    }
    // created 时会是 personInfo 的 nodeName 为空，在这里补上
    this.personFieldChanged(
      specialConfigField.node.fieldName,
      this.localPersonInfo.nodeGuid
    );
    // 准备 DateOfBirth 和 AgeDisplay
    await this.updatePersonAgeFromDob(this.localPersonInfo.dateOfBirth);
  }
};
</script>
